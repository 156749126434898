import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

import { db, authService } from '../firebase';
import { collection, getDocs, doc, deleteDoc, query, where, or} from "firebase/firestore";
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import logo from "../assets/FIRSTEVEN.png";

function Admin() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  //first rendering
  const getFitting = async () => {
    try{
      const usersCollectionRef = collection(db, 'fittingInfo'); // 참조
      const userSnap = await getDocs(usersCollectionRef); // 데이터 스냅 받아오기 - 비동기처리
      const data = userSnap.docs.map(doc => ({
        id: doc.id,
          ...doc.data(),
      }));
      setData(data);
    }
    catch(e){
      console.log(e.massage);
    }
}

//delete
const onDelete = async (data) => {
  const ok = window.confirm("삭제하시겠습니까?");
  const storage = getStorage();
  const desertRef = ref(storage, 'samputt_result/'+data.reservation_id)
  if (ok) {
      await deleteDoc(doc(db, "fittingInfo", data.id));
      //storage 접근해서 파일도 함께 삭제
      deleteObject(desertRef).then(()=>{

      }).catch((error) =>{
        
      })
      getFitting();
    }
}
  //new registration
  const navigateToAddFitting = () => {
    navigate("/register");
  };
  //logout
  const onLogOutClick = () => {
    authService.signOut();
    navigate('/login');
  };

  //for update by change
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {if (!user) navigate("/login");});
    
    getFitting();
  }, []); 


  //search
  const [keyword, setKeyword] = useState("")
  const [searchResult, setSearchResult] = useState([])
  const onChange =(e) =>{
    const {target: {value}, } = e;
      setKeyword(value)
  }

  const onSearch = async () => {
    const usersCollectionRef = collection(db, 'fittingInfo'); // 참조
    const q = query(usersCollectionRef, where("reservation_name", "==", keyword));
    const querySnapshot = await getDocs(q);
    const resultdata = querySnapshot.docs.map(doc => ({
      id: doc.id,
        ...doc.data(),
    }));
    setData(resultdata);
    console.log(resultdata);
    if (!resultdata.length) {
      window.alert("검색 결과가 없습니다.");
      navigate(0);
    }
  }
  const activeEnter = (e) => {
    if(e.key === "Enter") {
      onSearch();
    }
  }


  return (
    <div className='epr_admin'>
      <img src={logo} id="logo"/>
      
      <div id='toolbar'>
      <input 
        className='search_info'
        value = {keyword}
        onChange={onChange}  
        onKeyDown={(e) => activeEnter(e)}    
        placeholder='이름'></input>
      <button id="search" onClick={onSearch}>조회</button>
      <button id="register" onClick={navigateToAddFitting}>신규 등록</button>
    </div>


<table id="data_table">
    <thead id="head">
          <tr>
            <th>Ph.</th>
            <th>Name</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody id="body">
          {data.map((userResult, idx) => {
            return (
              <tr key={idx} id="data_body">
              <td id="search_key" onClick={() => navigate('/fittingresult/'+userResult.reservation_id)}>{userResult.reservation_id}</td>
              <td>{userResult.reservation_name}</td>
              <td>
                {userResult.date.toDate().getFullYear()}-
                {userResult.date.toDate().getMonth()}-{userResult.date.toDate().getDate()}&nbsp;
                {userResult.date.toDate().getHours()}:{userResult.date.toDate().getMinutes()}                
                </td>
                <td className='align-right'>
                  <button id="delete" onClick={() => onDelete(userResult)}>삭제</button>
                  </td>
              </tr>
            )
          })}


        </tbody>

</table>

    <button id ="logout" onClick={onLogOutClick}>LOGOUT</button>

    </div>
  )
}

export default Admin;