import { styled } from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 580px;
  padding: 100px 0px;
  margin: 0 auto;
`;

// export const Title = styled.h1`
//   font-size: 42px;
// `;

export const Form = styled.form`
  margin-top: 100px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: center;
  
`;

export const Input = styled.input`
  padding: 15px 20px;
  border-radius: 4px;
  border: 1px solid #efefef;
  width: 540px;
  font-size: 16px;
  &[type="submit"] {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Error = styled.span`
  font-weight: 600;
  color: tomato;
`;