import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';

import { db, authService } from '../firebase';
import { collection, getDocs, doc, query, where, and} from "firebase/firestore";
import logo from "../assets/w_FIRSTEVEN.png";
import titleImg from "../assets/main_epr.png";
import { Wrapper, Title, Form, Input, Error } from "../components/auth-components";


function Home() {
  const navigate = useNavigate();

  const [kName, setKName] = useState("")
  const [kNumber, setKNumber] = useState("")
  const [result, setResult] = useState("")
  let findId;


  const onChange =(e) =>{
    const {target: {name, value}, } = e;
    if (name === "name") {
      setKName(value);
    } else if (name === "number") {
      setKNumber(value);
    }
  }
  const goToResult = async () => {
    const usersCollectionRef = collection(db, 'fittingInfo');
    const q = query(usersCollectionRef, and(where("reservation_name", "==", kName), where("reservation_id", "==", kNumber)));
    const querySnapshot = await getDocs(q);
    
    querySnapshot.forEach((doc) => {
        findId = { ...doc.data()}
    }
  );
    if (findId) {navigate("/fittingresult/"+findId.reservation_id);}
    else{
      const check = window.confirm("성함과 휴대폰 번호를 정확히 입력해주세요");
      if(check){
        navigate(0);
      }
    }

  }
  


  return (
    <div className='epr_main_b'>

      <img src={logo} id="logo"/>

      <div className='epr_mobile'>
        <img src={titleImg} />
        <form className='m_search_form'>
          <input
          className='m_search_info'
          onChange={onChange}
          name="name"
          value={kName}
          placeholder='예약자명'
          required
          />

          <input 
          className='m_search_info'
          onChange={onChange}
          name="number"
          value={kNumber}
          placeholder='휴대폰번호 뒤 8자리(- 제외)'
          required/>

        <input className="epr_btn" onClick={goToResult} value="피팅 결과 보러가기"/>
        </form>
      </div>
    </div>
  )
}

export default Home;