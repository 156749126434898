import React, { useEffect, useRef, useState } from 'react';
import Checkbox from '../components/Checkbox';
import CheckboxGroup from '../components/CheckboxGroup';
import { db } from '../firebase';
import { collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadString } from 'firebase/storage';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import logo from "../assets/FIRSTEVEN.png";


export default function Register(){

  //data variables
    const [rId, setRId] = useState("");
    const [rName, setRName] = useState("");
    const [item, setitem] = useState([]);
    const [attachment, setAttachment] = useState();
    const [file, setFile] = useState("");
    const [comment, setComment] = useState("");

    const navigate = useNavigate();

    //데이터 업로드//
    const fittingCollectionRef = collection(db, "fittingInfo");
    const addData = async (evt) => {

      if (rId === "" || rName === "" ){
        window.alert("피팅 정보를 입력해주세요.")
      }
      else{
      try {
        const res = await addDoc(fittingCollectionRef, {
          reservation_id: rId,
          reservation_name: rName,
          file_ref: file,
          date: new Date(),
          recommand_item : item,
          fitter_comment: comment
        }); 

        //파일 업로드
        evt.preventDefault();
        const storage = getStorage();
        const fileRef = ref(storage, 'samputt_result/'+rId);
        setFile(fileRef);
        if (attachment){
          await uploadString(fileRef, attachment, 'data_url');
          navigate("/admin");
        }
        else{ window.alert("파일을 선택해 주세요.")
}
        
      } catch (e) {
        console.log(e);
      }
    }
    };


    const onChange = (e) => {
      const {target: {name, value}, } = e;
    if (name === "rId"){setRId(value);}
    else if (name === "rName"){setRName(value);}
    else if (name === "file"){
      const files = e.target.files;
      const theFile = files[0];
   
      // FileReader 생성
      const reader = new FileReader();
   
      // file 업로드가 완료되면 실행
      reader.onloadend = (finishedEvent) => {
        // 업로드한 이미지 URL 저장
        const result = finishedEvent.currentTarget.result;
        setAttachment(result);
      };
      // 파일 정보를 읽기
      reader.readAsDataURL(theFile);
    }
    else if (name === "comment"){setComment(value);}    
    }

    useEffect(() => {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {if (!user) navigate("/login");});
    }, []); 
  

    return(
        <div className='admin_register'>
      <img src={logo} id="logo" onClick={()=> {navigate("/admin")}}/>
      <div id='fitting_analy'>    
          <div className="reservationInfo">
              <p className='title'>예약정보</p>
              <div className='inputSet'>
                <div className="inputWrap" id="input_name_wrap">
                    <input 
                    className="input_info"
                    type="text"
                    value={rName}
                    name="rName"
                    onChange={onChange}
                    placeholder="예약자명"
                    required></input>
                </div>
                <div className="inputWrap" id="input_phone_wrap">
                    <input
                    className="input_info"
                    value={rId}
                    name="rId"
                    onChange={onChange}
                    placeholder="휴대폰 번호 뒤 8자리 ('-' 제외)"
                    required></input>
                </div>
              </div>
            </div>
  
          <p className='title'>결과 파일 업로드</p>
          <div id="file_area"><input 
              type="file"
              name="file"
              onChange={onChange} 
              required/>
              </div>
  

            
          <p className='title'>추천 모델 선택</p>         
            <CheckboxGroup
                values={item}
                onChange={setitem}
              >
              <Checkbox value="er1.2">네오클래식 ER1.2</Checkbox>

              <Checkbox value="er2">네오클래식 ER2</Checkbox>

              <Checkbox value="er2.2">네오클래식 ER2.2</Checkbox>

              <Checkbox value="er5">네오클래식 ER5</Checkbox>
            
              <Checkbox value="er8">네오클래식 ER8</Checkbox>
            </CheckboxGroup>


          <div className='comment_area'>
            <p className='title'>피터 코멘트</p>
          <textarea
            type="text"
            className="input_info"

            value={comment}
            name='comment'
            onChange={onChange}
            placeholder="내용을 입력해주세요."></textarea>
            </div>

          <button className="upload_btn" onClick={addData}>완료</button>
          </div>
        </div>
 )
}