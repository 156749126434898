import React, { useEffect, useState } from "react";
import { useParams, useLocation,useNavigate, Navigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, getDocs } from "firebase/firestore";
import { getBlob, getDownloadURL, getStorage, ref } from 'firebase/storage';
import logo from "../assets/FIRSTEVEN.png";

import Loading from './Loading';

export default function FittingResult(){

  const [loading, setLoading] = useState(true);


    const navigate = useNavigate();
    const { reservationId } = useParams();

    const fittingCollectionRef = collection(db, "fittingInfo");
    const q = query(fittingCollectionRef, where("reservation_id", "==", reservationId));

    const [data, setData] = useState({});
    const [rName, setRName] = useState("");
    const [items, setitems] = useState([""]);
    const [comment, setComment] = useState("");

    let info;

    const dataLoadinig = async () => {
        setLoading(true);

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        setData({ ...doc.data() });
        info = {id: doc.id, ...doc.data()}
        setRName(info.reservation_name);
        setitems(info.recommand_item);
        setComment(info.fitter_comment);
        });
        if(info){
          setLoading(false);
        }
    }


    useEffect( ()=>{
        dataLoadinig();
    },[])

    const openFile = (e) => {
        const storage = getStorage();
        getDownloadURL(ref(storage, 'samputt_result/'+reservationId))  
        .then((url) => {
            // `url` is the download URL for 'images/stars.jpg'
            window.open(url);
          })
          .catch((error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/object-not-found':
                // File doesn't exist
                break;
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;
        
              // ...
        
              case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break;
            }
          });
    }


    const loadImg = async (src, i) => {
        if (src){
        const storage = getStorage();
        await getDownloadURL(ref(storage, 'neoClassics/'+src+'_800.png'))
        .then((url) => {
            const img = document.getElementById('recommand'+i);
            img.setAttribute('src', url);
        })
        }
    }

    return(
      <div>          
        {loading ? <Loading /> : 
        <div className="epr_main">
          <img src={logo} id="logo"/>

<div className="fitting_area">
<h3 className="fitting_title">{rName}님의 피팅 결과<br />추천 모델</h3>

        <div className="m_recommand">
            {items.map((item,idx) => {
                loadImg(item, idx);
                return(
                    <div key={idx} className="result_recommand">
                        <img id={"recommand"+idx} className="result_img"></img>
                        <p className="result_title">NEO CLASSIC {item}</p>

                    </div>
                )
            })}

        </div>

    <div className="comment_area">
        <h3 className="fitting_title">피터 코멘트</h3>
        <textarea readonly="readonly" className="m_comment_box">{comment}</textarea>
    </div>
    <button id="fileplz" onClick={openFile}>SAM-PUTT 결과지 확인하기</button>
    <p id="info">※ 다운로드 유효기간 7일</p>
</div></div>
}
</div>
    )
}