import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Router, Routes, useNavigate } from 'react-router-dom';
import React, {useEffect} from "react";


import Home from './pages/Home';
import Admin from './pages/Admin';
import Login from './pages/login';
import FittingResult from './pages/FittingResult';
import Register from './pages/Register';
import Modify from './pages/Modify';

function App() {


  return (
    <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path='/fittingresult/:reservationId' element={<FittingResult />} />

      {/* 관리자 only access */}

          <Route path="/admin" element={<Admin />} />
          <Route path="/register" element={<Register />} />
          <Route path='/modify/:reservationId' element={<Modify /> } />
      </Routes>
    </BrowserRouter>
    </div>
  );
}


export default App;
