import React, { useEffect, useState } from 'react';
import Checkbox from '../components/Checkbox';
import CheckboxGroup from '../components/CheckboxGroup';

import { db } from '../firebase';
import { collection, query, where, getDocs, updateDoc, doc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';
import { useParams, useLocation,useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import logo from "../assets/FIRSTEVEN.png";


export default function Modify(){

    const navigate = useNavigate();

    const [data, setData] = useState({})
    const { reservationId } = useParams();
    let info;

    //data variables
    const [id, setId] = useState("");
    const [rId, setRId] = useState("");
    const [rName, setRName] = useState("");
    const [item, setitem] = useState([""]);
    const [attachment, setAttachment] = useState();
    const [comment, setComment] = useState("");
    
    const fittingCollectionRef = collection(db, "fittingInfo");

    const dataLoadinig = async () => {
      const q = query(fittingCollectionRef, where("reservation_id", "==", reservationId));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        
      setData({ ...doc.data() });
      info = {id: doc.id, ...doc.data()}

      setId(info.id);
      setRId(info.reservation_id);
      setRName(info.reservation_name);
      // setRPhone(info.reservation_phone);
      setitem(info.recommand_item);
      setComment(info.fitter_comment);
      }
    );

    //잘못된 링크
    if(!info){
      window.alert("사용자 정보가 없습니다.");
      navigate("/admin");
    }
  }


    useEffect( ()=> {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {if (!user) navigate("/login");});
      dataLoadinig();
    }, [])

    const fileOpen = () => {
      const storage = getStorage();
      const pathReference = ref(storage, 'samputt_result/'+rId);
      console.log("WANT DOWNLOAD!!!");
    }

    const updateFittingInfo = async (evt) => {
      await updateDoc(doc(fittingCollectionRef, id),{
        reservation_id: rId,
        reservation_name: rName,
        recommand_item : item,
        fitter_comment: comment
      });
              //파일 업로드
        // evt.preventDefault();
        // const storage = getStorage();
        // const fileRef = ref(storage, 'samputt_result/'+rId);
        // await uploadString(fileRef, attachment, 'data_url');
       
      navigate("/admin");  
    }

   const onChange = e => {
      const {target: {name, value},} = e;
      if (name === "rId"){setRId(value);}
      else if (name === "rName"){setRName(value);}
      // else if (name === "rPhone"){setRPhone(value);}
      else if (name === "file"){
        const files = e.target.files;
        const theFile = files[0];
     
        // FileReader 생성
        const reader = new FileReader();
     
        // file 업로드가 완료되면 실행
        reader.onloadend = (finishedEvent) => {
          // 업로드한 이미지 URL 저장
          const result = finishedEvent.currentTarget.result;
          setAttachment(result);
        };
        // 파일 정보를 읽기
        reader.readAsDataURL(theFile);
      }
      else if (name === "comment"){setComment(value);}    
      }


    return(
      <div className='admin_register'>
      <img src={logo} id="logo" onClick={()=> {navigate("/admin")}}/>
      <div id='fitting_analy'>    
        <div className="reservationInfo">
          <p className='title'>예약정보</p>
            <div className='inputSet'>
              <div className="inputWrap" id="input_name_wrap">
                <input
                className="input_info"
                value={rId}
                name="rId"
                onChange={onChange}
                placeholder="예약번호"
                required></input>
            </div>
            <div className="inputWrap" id="input_name_wrap">
                <input 
                    className="input_info"
                type="text"
                value={rName}
                name="rName"
                onChange={onChange}
                placeholder="예약자명"
                required></input>
            </div>
          </div>
        </div>
     

      <p className='title'>결과 파일 업로드</p>            
          <div id="file_area"><input 
              type="file"
              name="file"
              onChange={onChange} 
              required/>
              </div>
  

                <button onClick={fileOpen}>{rId}.pdf</button>
          


            <div> 추천 모델 선택
            <CheckboxGroup
                // label="좋아하는 색깔은?"
                values={item}
                onChange={setitem}
              >
              <Checkbox value="er1.2">네오클래식 ER1.2</Checkbox>
              <Checkbox value="er2">네오클래식 ER2</Checkbox>
              <Checkbox value="er2.2">네오클래식 ER2.2</Checkbox>
              <Checkbox value="er5">네오클래식 ER5</Checkbox>
              <Checkbox value="er8">네오클래식 ER8</Checkbox>
            </CheckboxGroup>
            </div>



            <div>피터 코멘트
            <input 
            type="text"
            value={comment}
            name='comment'
            onChange={onChange}
            placeholder="내용을 입력해주세요."></input>
            </div>

          <button onClick={updateFittingInfo}>완료</button>

        </div></div>
 )
}